








































































import { Empresa } from '@/core/models/geral';
import { PageBase } from '@/core/models/shared';
import { DespesaService } from '@/core/services/financeiro';
import { EmpresaService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class DetalharRegistroOperacionalEquipamento extends PageBase { 

    service = new DespesaService();
    itens: any[] = [];
    empresaService = new EmpresaService();
    empresa: Empresa = new Empresa();
    overlay: boolean = false;
    filtro: any = {
        numeroRo: null,
        fornecedorId: null,
        embarcacaoId: null,
        tipoDespesaId: null,
        dataInicio: null,
        dataFim: null,
        dataSaida: null
    };

    mounted() {
        this.filtro.numeroRo = this.$route.query.numeroRo;
        this.filtro.fornecedorId = this.$route.query.fornecedorId;
        this.filtro.embarcacaoId = this.$route.query.embarcacaoId;
        this.filtro.tipoDespesaId = this.$route.query.tipoDespesaId;
        this.filtro.dataInicio = this.$route.query.dataInicio;
        this.filtro.dataFim = this.$route.query.dataFim;
        this.filtro.dataSaida = this.$route.query.dataSaida;
        this.Carregar();
    }

    Carregar(){
        this.overlay = true;
        
        this.empresaService.ObterPorId(this.app.empresaId, "Endereco.Municipio.Estado").then(
            res=>{
                this.empresa = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        )

        this.service.DetalharEquipamento(this.filtro.numeroRo, this.filtro.fornecedorId, this.filtro.embarcacaoId, this.filtro.tipoDespesaId, this.filtro.dataInicio, this.filtro.dataFim,this.filtro.dataSaida).then(
            res => {
                this.itens = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }
}
